import COLORS from '@utils/colors';
import css from 'styled-jsx/css';

const styles = css`
  .bottom-nav {
    z-index: 5;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 64px;

    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 24px 10px;

    box-shadow:
      0px -2px 4px -2px rgba(24, 39, 75, 0.08),
      0px -4px 4px -2px rgba(24, 39, 75, 0.04);
    background-color: ${COLORS.white};

    .icons {
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 8px;
      align-items: flex-start;
      width: 100%;
      max-width: 410px;

      :global(.favorite) {
        fill: ${COLORS.white};
      }
    }
  }
`;

export default styles;
